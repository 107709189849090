import cronstrue from "cronstrue";

export default class FormatValidator {
  private static tokenRegex = /--\*.+\*--/;
  private static variableTokenRegex = /--\*\$.+\*--/;
  private static liquidRegex = /\{\{.+\}\}/;
  private static emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static email(email: string, restrictTokenToVariable: boolean = false): boolean {
    var splitEmails = email.split(";");

    return splitEmails.every((item) => {
      const emailLower = item.trim().toLowerCase();
      const isToken = restrictTokenToVariable
        ? this.variableTokenRegex.test(emailLower)
        : this.tokenRegex.test(emailLower);
      return isToken || this.emailRegex.test(emailLower);
    });

  }

  public static groupName(group: string){
    var regex = RegExp("^[a-zA-Z0-9-]+$")
    return regex.test(group)
  }

  public static optionalEmail(email: string){
    return email === "" || FormatValidator.email(email);
  }

  public static password(password: string): boolean {
    var regex =
      /^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$/;
    return regex.test(String(password).toLowerCase());
  }

  public static number(number: string): boolean {
    var regex =  /^\d+$/;
    return this.tokenRegex.test(String(number)) || regex.test(String(number));
  }

  public static mobileNumber(number: string): boolean {
    var regex = /^\+\d{12}$/;
    return this.tokenRegex.test(String(number)) || regex.test(String(number));
  }

  public static faxNumber(number: string): boolean {
    var regex = /^\+\d{7,}$/;
    return this.tokenRegex.test(String(number)) || regex.test(String(number));
  }

  public static cron(expression: string): boolean {
    try {
      return !!cronstrue.toString(expression);
    } catch {
      return false;
    }
  }

  public static ip(ip: string): boolean {
    var regex =
      /^^(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(((\/([4-9]|[12][0-9]|3[0-2]))?)|\s?-\s?((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))))(,\s?|$))+/;
    return regex.test(String(ip));
  }

  public static url(url: string): boolean {
    var regex =
    //eslint-disable-next-line no-useless-escape
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
    return this.mappedValue(url) || regex.test(url);
  }

  public static statusCode(statusCode: string): boolean {
    var regex = /^[1-5][0-9][0-9]$/;
    return this.tokenRegex.test(statusCode) || regex.test(statusCode);
  }

  public static filename(filename: string): boolean {
    var regex = /^[a-zA-Z0-9._ -/]+$/;
    return this.tokenRegex.test(filename) || regex.test(filename);
  }

  public static port(port: string): boolean {
    if (this.mappedValue(port)) return true;
    return !isNaN(Number(port)) && 0 <= Number(port) && Number(port) <= 65535;
  }

  public static mappedValue(input: string): boolean {
    return this.tokenRegex.test(input) || this.liquidRegex.test(input);
  }
}
