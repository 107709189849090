import { FieldState, FormState } from "formstate";
import { autorun, computed, observable } from "mobx";
import { sampleXslt } from "../SampleObject";
import AppContext from "../../../App.Context";
import AuthenticationContext from "./AuthenticationContext";
import { Behaviour } from "../../../Common/Enums/Behaviour";
import BehaviourHelpers from "../../../Common/Helpers/BehaviourHelpers";
import Constants from "../../../Common/Constants";
import DataFactory from "../../../Common/Factories/DataFactory";
import { DataFormat } from "../../../Common/Enums/DataFormat";
import { DataSource } from "../../../Common/Enums/DataSource";
import EndpointHelpers from "../../../Common/Helpers/EndpointHelpers";
import EndpointService from "../../../Common/Services/Endpoint.Service";
import { EndpointStepType } from "../EndpointStepType";
import { EndpointType } from "../../../Common/Enums/EndpointType";
import ExampleHelpers from "../../../Common/Helpers/ExampleHelpers";
import FHIRHelper from "../../../Common/HL7/FHIRHelper";
import { FHIRR4Resource } from "../../../Common/HL7/4.0.0 (R4 Sequence)/Resource";
import FormatValidator from "../../../Common/Helpers/FormatValidator";
import { HttpMethod } from "../../../Common/Enums/HttpMethod";
import IDictionary from "../../../Common/Interfaces/IDictionary";
import IEndpoint from "../../../Common/Interfaces/IEndpoint";
import IEndpointXmlSchema from "../../../Common/Interfaces/IEndpointXmlSchema";
import { IExampleOutput } from "../IExampleOutput";
import IFunction from "../../../Common/Interfaces/IFunction";
import { IJSONPathMatch } from "../../../Common/Interfaces/IJSONPathMatch";
import INewVersion from "../../Endpoints/INewVersion";
import IProperty from "../../../Common/Interfaces/IProperty";
import ISchemaGenerationResult from "../../../Common/Interfaces/ISchemaGenerationResult";
import ISecret from "../../../Common/Interfaces/ISecret";
import ISelectedVersion from "../../Endpoints/ISelectedVersion";
import IStep from "../../../Common/Interfaces/IStep";
import IValidationResult from "../../../Common/Interfaces/IValidationResult";
import IVariable from "../../../Common/Interfaces/IVariable";
import { MapType } from "../../../Common/Enums/MapType";
import Prettifier from "../../../Common/Helpers/Prettifier";
import PropertyHelpers from "../../../Common/Helpers/PropertyHelpers";
import RepositoriesService from "../../Repositories/Repositories.Service";
import { ResponseHandlerType } from "../../../Common/Enums/ResponseHandlerType";
import SecretsContext from "../../Secrets/Secrets.Context";
import Serializers from "../../../Common/Helpers/Serializers";
import StepContext from "./Step.Context";
import TaskContext from "./Task.Context";
import debounce from "lodash.debounce";

export default class EndpointWizardContext {
  public constructor(
    appContext: AppContext,
    editMode: boolean,
    readOnly: boolean
  ) {
    this._appContext = appContext;
    this.editMode = editMode;
    this.readOnly = readOnly;
    this.loading = true;
    this._endpointHelpers = new EndpointHelpers(appContext.managementHost);
    this.exampleHelpers = new ExampleHelpers(appContext);

    const endpointServicePromise = EndpointService.GetEndpoints(appContext);
    const repositoriesServicePromise =
      new RepositoriesService().GetRepositories(appContext);

    Promise.all([
      endpointServicePromise,
      repositoriesServicePromise,
      appContext.contextPromise,
    ])
      .then(([endpoints, repositories]) => {
        this.endpoints = this.endpoints
          .concat(
            endpoints.map((endpoint) => ({
              ...endpoint,
              type: EndpointType.Wizard,
            }))
          )
          .concat(
            repositories
              .flatMap((repository) => repository.endpoints)
              .filter((ep): ep is IEndpoint => !!ep)
              .map((endpoint) => ({
                ...endpoint,
                type: EndpointType.Repository,
              }))
          );
      })
      .finally(() => (this.loading = false));

    this.input = new StepContext();
    this.initializeInputData(DataFormat.JSON, editMode);
    this.secretsContext = new SecretsContext(appContext);
  }
  private _appContext: AppContext;
  private _endpointHelpers: EndpointHelpers;
  public exampleHelpers: ExampleHelpers;
  public secretsContext: SecretsContext;

  @observable
  public editMode: boolean;

  @observable
  public readOnly: boolean;

  @observable
  public endpoints: IEndpoint[] = [];

  @observable
  public endpointVersion?: ISelectedVersion | INewVersion;

  @observable
  public endpoint?: IEndpoint;

  @observable
  public input: StepContext;

  @observable
  public inputContent: string = "";

  @observable
  public editInitialLoad: boolean = false;

  @observable
  public stopOnTaskFailure: boolean = true;

  @observable
  public method: HttpMethod = HttpMethod.POST;

  @observable
  public tasks: TaskContext[] = [];

  @observable
  public authenticationMethods: AuthenticationContext[] = [];

  @observable
  public activeTask: number = 0;

  @observable
  public activeStep: number = 0;

  @observable
  public loading: boolean = false;

  @observable
  public redirect: boolean = false;

  @observable
  public error: boolean = false;

  @observable
  public draggedProperty: IProperty | undefined;

  @observable
  public draggedFunction: IFunction | undefined;

  @observable
  public draggedSecret: ISecret | undefined;

  @observable
  public draggedVariable: IVariable | undefined;

  @observable
  public draggableKey?: string;

  @observable
  public editingPathMatches: IJSONPathMatch[] | any[] = [];

  @observable
  public editedPath?: string;

  @observable
  public editingPathError: boolean = false;

  @observable
  public version: number = 1;

  @observable
  public derivedVersion?: number;

  @observable
  public retainStatusCode: boolean = true;

  @observable
  public retainResponseHandlerStatusCode: boolean = false;

  @observable
  public saveInput: boolean = false;

  @observable
  public deploymentEnvironments: string[] = [];

  @observable
  public inputSchema?: string;

  @observable
  public xmlInputSchemas?: IEndpointXmlSchema[];

  @observable
  public generatable: boolean = true;

  @observable
  public validationResult?: IValidationResult;

  @observable
  public confirmSyncSchema: boolean = true;

  @observable
  public saveError: string = "";

  @computed
  public get selectedDataOrigin(): StepContext {
    return this.tasks[this.activeTask].mappingSelectedDataOrigin >= 0
      ? this.tasks[this.tasks[this.activeTask].mappingSelectedDataOrigin].output
      : this.input;
  }

  /**
   *  MAG-610
   *  Disable importing and dragging of tokens if map type is XSLT
   */
  @observable
  public isEditorTokenImportEnabled: boolean = true;

  public endpointNameField = new FieldState("").validators(
    (val) =>
      (!RegExp("^[a-zA-Z0-9-]+$").test(val) &&
        "Endpoint name containing letters, numbers and dashes (-) only required") ||
      (val &&
        this.endpoints.some(
          (e) => e.name.toUpperCase() === val.toUpperCase()
        ) &&
        !this.editMode &&
        "This endpoint name is already in use")
  );
  public errorEmailField = new FieldState("").validators(
    (val) => {
      const restrictTokenToVariable = true;
      return val && !FormatValidator.email(val, restrictTokenToVariable) && "Enter a valid email address or variable reference (--*$my-variable*--)"
    }
  );
  public endpointGroupField = new FieldState("").validators(
    (val) =>
      (val &&
        !FormatValidator.groupName(val) &&
        "Group name containing letters, numbers and dashes (-) only required") ||
      (val && val.length > 30 && "Maximum character limit exceeded")
  );

  endpointForm = new FormState({
    endpointName: this.endpointNameField,
    endpointGroup: this.endpointGroupField,
    errorEmail: this.errorEmailField,
  });

  @computed
  public get steps() {
    let steps: IStep[] = [{ label: "Tasks", type: EndpointStepType.Behaviour }];
    steps.push({ label: "Input", type: EndpointStepType.Input });
    steps = steps.concat(
      this.tasks.map((t) => {
        return {
          label: `Output: ${BehaviourHelpers.GetDisplayName(
            t.behaviour,
            t.adapter?.name
          )} ${t.labelField.value ? `[${t.labelField.value}]` : ""}`,
          type: EndpointStepType.Output,
        };
      })
    );
    steps.push({ label: "Deploy", type: EndpointStepType.PreviewDeploy });
    return steps;
  }

  public requestUpdatePreview = (): Promise<string> => {
    return EndpointService.Preview(
      this._appContext,
      {
        input: this.inputContent,
        output: this.tasks[this.activeTask].getContent(true),
        paths: this.tasks[this.activeTask].addedProperties,
        pathOrigins: this.tasks[this.activeTask].addedPropertyOrigins,
        functions: this.tasks[this.activeTask].functions,
        inputFormat: this.input.mode,
        outputFormat: this.tasks[this.activeTask].output.mode,
        mapType: this.tasks[this.activeTask].output.mapType,
        xsltParameters: this.tasks[this.activeTask].xsltParameters.map((p) => {
          return { name: p.name.content, value: p.value.content };
        }),
        taskResults: EndpointHelpers.GetTaskResults(this.tasks),
        dataOrigin: this.tasks[this.activeTask].output.selectedDataOrigin,
      },
      this.endpointNameField.value,
      this.version
    );
  };

  public requestUpdateResponseHandlerSchema =
    (): Promise<ISchemaGenerationResult> => {
      const data = Serializers.parseIfValid(
        this.tasks[this.activeTask].responseHandlerContent.content,
        this.tasks[this.activeTask].output.mode
      );
      if (data && this.tasks[this.activeTask].editInitialLoad === false) {
        return this._endpointHelpers.GenerateSchema(
          data,
          this.tasks[this.activeTask].output.mode
        );
      } else {
        return Promise.resolve({});
      }
    };

  public requestUpdateOutput = (): Promise<
    string | ISchemaGenerationResult
  > => {
    if (
      this.tasks[this.activeTask].behaviour === Behaviour.ResponseHandler &&
      this.tasks[this.activeTask].responseHandlerTypeField.value ===
        ResponseHandlerType.Receive
    ) {
      return this.requestUpdateResponseHandlerSchema();
    } else {
      return this.requestUpdatePreview();
    }
  };

  public async updateResponseHandlerSchema(task: number) {
    this.tasks[task].responseHandlerXmlSchemas = undefined;
    this.tasks[task].responseHandlerJsonSchema = undefined;

    if (
      this.tasks[task].responseHandlerContent.content &&
      this.tasks[task].responseHandlerTypeField.value ===
        ResponseHandlerType.Receive
    ) {
      this.requestUpdateResponseHandlerSchema().then((result) => {
        this.tasks[task].responseHandlerXmlSchemas = result.xmlSchemas;
        this.tasks[task].responseHandlerJsonSchema = result.jsonSchema;
        if (result.xmlSchemas) {
          this.tasks[task].output.previewXsdNamespace =
            result.xmlSchemas[0].namespace;
        }
      });
    }
  }

  public updatePreview = () => {
    if (
      this.tasks[this.activeTask].behaviour === Behaviour.ResponseHandler &&
      this.tasks[this.activeTask].responseHandlerTypeField.value ===
        ResponseHandlerType.Receive
    ) {
      if (this.tasks[this.activeTask].editInitialLoad === false) {
        this.updateResponseHandlerSchema(this.activeTask);
      }
      this.tasks[this.activeTask].output.modified = false;
    } else {
      this.tasks[this.activeTask].updateHL7v2OutputData();
      this.loading = true;
      this.requestUpdatePreview()
        .then((result) => {
          this.tasks[this.activeTask].preview = Prettifier.prettify(
            result,
            this.tasks[this.activeTask].output.mode
          );
        })
        .catch(() => {
          this.tasks[this.activeTask].preview = "";
        })
        .finally(() => {
          this.loading = false;
          this.tasks[this.activeTask].output.modified = false;
        });
    }
  };

  public updateSchemaDebounced = debounce(() => this.updateInputSchema(), 500, {
    leading: true,
    trailing: true,
  });

  public updateInputSchema = async () => {
    const result = await this._endpointHelpers.GenerateSchema(
      this.input.data,
      this.input.mode
    );
    if (result.xmlSchemas !== undefined) {
      this.xmlInputSchemas = result.xmlSchemas;
      this.input.previewXsdNamespace = result.xmlSchemas[0].namespace;
    }
    if (result.jsonSchema !== undefined) {
      this.inputSchema = result.jsonSchema;
    }
  };

  private inputStepValid(): boolean {
    switch (this.input.mode) {
      case DataFormat.JSON:
      case DataFormat.HL7FHIRJSON:
        return (
          Serializers.parseJsonIfValid(this.inputContent) !== undefined &&
          Serializers.parseJsonIfValid(this.inputSchema as string) !== undefined
        );
      case DataFormat.XML:
      case DataFormat.HL7FHIRXML:
        // This doesn't check xsd imports etc, i.e. not full validation
        return (
          Serializers.parseXmlIfValid(this.inputContent) !== undefined &&
          (this.xmlInputSchemas?.every(
            (xmlSchema) =>
              Serializers.parseXmlIfValid(xmlSchema.schema) !== undefined
          ) ||
            false)
        );
      case DataFormat.Turtle:
        return (
          Serializers.parseTurtleIfValid(this.inputSchema as string) !==
          undefined
        );
      case DataFormat.HL7v2:
        return (
          Serializers.parseHL7v2IfValid(this.inputContent) !== undefined &&
          Serializers.parseJsonIfValid(this.inputSchema as string) !==
            undefined &&
          !this.input.error
        );
      default:
        return true;
    }
  }

  private outputStepValid(task: number) {
    switch (this.tasks[task].behaviour) {
      case Behaviour.Email:
        return EndpointHelpers.isEmailTaskValid(this.tasks[task]);
      case Behaviour.MESH:
        return EndpointHelpers.isMESHTaskValid(this.tasks[task]);
      case Behaviour.SMS:
        return EndpointHelpers.isSMSTaskValid(this.tasks[task]);
      case Behaviour.Fax:
        return EndpointHelpers.isFaxTaskValid(this.tasks[task]);
      case Behaviour.Job:
        return EndpointHelpers.isJobTaskValid(this.tasks[task]);
      case Behaviour.Request:
        return EndpointHelpers.isRequestValid(this.tasks[task]);
      case Behaviour.PDF:
        return true;
      case Behaviour.ResponseHandler:
        return EndpointHelpers.isResponseHandlerValid(this.tasks[task]);
      case Behaviour.Response:
        return EndpointHelpers.isResponseValid(this.tasks[task]);
      case Behaviour.Adapter:
        return EndpointHelpers.isAdapterTaskValid(
          this.tasks[task],
          this._appContext.adapters!.find(
            (adapter) => adapter.name === this.tasks[task].adapter!.name
          )
        );
      case Behaviour.SFTP:
        return EndpointHelpers.isSftpTaskValid(this.tasks[task]);
      case Behaviour.JWT:
        return EndpointHelpers.isJwtTaskValid(this.tasks[task]);
      case Behaviour.AireAudit:
        return EndpointHelpers.isAireAuditTaskValid(this.tasks[task]);
      default:
        return this.tasks[task].previewIsValid;
    }
  }

  public valid(step: EndpointStepType, task?: number): boolean {
    let valid = false;
    if (step === EndpointStepType.Input) {
      valid = this.inputStepValid();
    }
    if (step === EndpointStepType.Output && task !== undefined) {
      valid = this.outputStepValid(task);
      if (this.input.mode === DataFormat.Stream) {
        valid = valid && this.streamEndpointStepValid(task);
      }
    }

    return valid;
  }

  //Check that if we have mapped the input stream in the task's content we have no other content with it
  public streamEndpointStepValid(task: number): boolean {
    let content = this.tasks[task].getContent();
    //remove spaces and any trailing <p> tags
    content = content
      .replace(/\s/g, "")
      .replace(/<\/?p>/g, "")
      .toLowerCase();
    let containsStreamMapping =
      content.indexOf(Constants.StreamInputMapping) > -1 ||
      content.indexOf(Constants.StreamInputTokenMapping) > -1 ||
      content.indexOf(Constants.StreamResponseTokenMapping) > -1;
    console.log(containsStreamMapping);
    //if the stream isn't mapped, we don't need to check further
    if (!containsStreamMapping) {
      return true;
    }
    //if the stream is mapped, nothing else should be
    return (
      content === Constants.StreamInputMapping ||
      content === Constants.StreamInputTokenMapping ||
      content === Constants.StreamResponseTokenMapping
    );
  }

  public stepValid(stepIndex: number) {
    switch (this.steps[stepIndex].type) {
      case EndpointStepType.Behaviour:
        this.endpointForm.validate();
        return (
          this.endpointForm.hasError === false &&
          this.tasks.length > 0 &&
          this.tasks.every((t) => {
            t.taskForm.validate();
            return (
              t.taskForm.hasError === false &&
              this.authenticationMethods.every((a) => {
                a.form.validate();
                return a.form.hasError === false;
              })
            );
          })
        );
      case EndpointStepType.Input:
        return (
          this.input.loaded &&
          this.valid(EndpointStepType.Input) &&
          this.validationResult?.valid !== false
        );
      case EndpointStepType.Output:
        const stepFinder = 2;
        return (
          this.tasks[stepIndex - stepFinder].tests &&
          this.tasks[stepIndex - stepFinder].tests.every(
            (test) => test.active === false || test.output === test.actual
          ) &&
          this.tasks[stepIndex - stepFinder].filters &&
          this.tasks[stepIndex - stepFinder].filters.every(
            (filter) => !filter.error
          ) &&
          this.valid(EndpointStepType.Output, stepIndex - stepFinder)
        );
      case EndpointStepType.PreviewDeploy:
        return true;
      default:
        return false;
    }
  }

  public allPreceedingStepsValid(step: number) {
    if (this.readOnly) {
      return true;
    }
    // Check if all preceeding steps are valid
    return (
      [...Array(step).keys()].some((v, i) => this.stepValid(i) === false) ===
      false
    );
  }

  public addDraggedPropertyToOutput(
    task: number,
    token: string,
    dataOrigin?: number
  ) {
    if (this.draggedProperty) {
      let uniqueProperty = PropertyHelpers.getKey(token);
      this.tasks[task].setAddedProperty(
        uniqueProperty,
        this.draggedProperty.path,
        this.draggedProperty.origin
      );
    }
  }

  public getRequestExampleOutput(
    inputMode: DataFormat,
    outputMode: DataFormat,
    inputData: Document | {} | [],
    mapType: MapType = MapType.Path,
    task: number
  ): IExampleOutput {
    let output = "";
    const addedProperties = {};
    if (inputMode === DataFormat.None) {
      output = "";
    } else if (outputMode === DataFormat.JSON) {
      if (
        inputMode === DataFormat.XML &&
        (inputData as Document).documentElement
      ) {
        const nodeName = this.getDisplayNodeName(inputData);
        if (mapType === MapType.XSLT) {
          output = sampleXslt.replace(
            "{0}",
            `\n\t{"${nodeName}":"<xsl:value-of select="${PropertyHelpers.getPathToProperty(
              nodeName,
              false,
              DataFormat.XML,
              this.getDisplayParentNodePath(inputData)
            )}"/>"}\n`
          );
        } else {
          output = `{\n\t"${nodeName}": "${PropertyHelpers.getPropertyToken(
            nodeName
          )}"\n}`;
          (addedProperties as IDictionary<string>)[
            nodeName
          ] = `${PropertyHelpers.getPathToProperty(
            nodeName,
            false,
            DataFormat.XML,
            this.getDisplayParentNodePath(inputData)
          )}/text()`;
        }
      } else if (inputMode === DataFormat.HL7v2) {
        output = '{\n\t"headerName": "--*headerName*--"\n}';
        (addedProperties as IDictionary<string>)["headerName"] =
          "$.Header.Name";
      } else {
        if (
          typeof inputData === "object" &&
          (inputData as any).constructor !== Array
        ) {
          const propName = Object.keys(inputData)[0];
          output = `{\n\t"${propName}": ${PropertyHelpers.getPropertyToken(
            propName
          )}\n}`;
          (addedProperties as IDictionary<string>)[propName] =
            PropertyHelpers.getPathToProperty(propName, false, DataFormat.JSON);
        } else {
          output = `{\n\t"item": ${PropertyHelpers.getPropertyToken("[0]")}\n}`;
          (addedProperties as IDictionary<string>)["[0]"] =
            PropertyHelpers.getPathToProperty("[0]", false, DataFormat.JSON);
        }
      }
    } else if (outputMode === DataFormat.XML) {
      if (
        inputMode === DataFormat.XML &&
        (inputData as Document).documentElement
      ) {
        const nodeName = this.getDisplayNodeName(inputData);
        if (mapType === MapType.XSLT) {
          output = sampleXslt.replace(
            "{0}",
            `\n\t<${nodeName}><xsl:value-of select="${PropertyHelpers.getPathToProperty(
              nodeName,
              false,
              DataFormat.XML,
              this.getDisplayParentNodePath(inputData)
            )}"/></${nodeName}>\n`
          );
        } else {
          output = `<${nodeName}>${PropertyHelpers.getPropertyToken(
            nodeName
          )}</${nodeName}>`;
          (addedProperties as IDictionary<string>)[
            nodeName
          ] = `${PropertyHelpers.getPathToProperty(
            nodeName,
            false,
            DataFormat.XML,
            this.getDisplayParentNodePath(inputData)
          )}/text()`;
        }
      } else if (inputMode === DataFormat.HL7v2) {
        output = "<xml>--*headerName*--</xml>";
        (addedProperties as IDictionary<string>)["headerName"] =
          "$.Header.Name";
      } else {
        if (
          typeof inputData === "object" &&
          (inputData as any).constructor !== Array
        ) {
          const propName = Object.keys(inputData)[0];
          output = `<${propName}>${PropertyHelpers.getPropertyToken(
            propName
          )}</${propName}>`;
          (addedProperties as IDictionary<string>)[propName] =
            PropertyHelpers.getPathToProperty(propName, false, DataFormat.JSON);
        } else {
          output = `<item>${PropertyHelpers.getPropertyToken("[0]")}<item>`;
          (addedProperties as IDictionary<string>)["[0]"] =
            PropertyHelpers.getPathToProperty("[0]", false, DataFormat.JSON);
        }
      }
    } else if (outputMode === DataFormat.QueryString) {
      if (
        inputMode === DataFormat.XML &&
        (inputData as Document).documentElement
      ) {
        const nodeName = this.getDisplayNodeName(inputData);
        if (mapType === MapType.XSLT) {
          output = sampleXslt.replace(
            "{0}",
            `${nodeName}=<xsl:value-of select="translate(${PropertyHelpers.getPathToProperty(
              nodeName,
              false,
              DataFormat.XML,
              this.getDisplayParentNodePath(inputData)
            )}, ' ', '+')"/>`
          );
        } else {
          output = `${nodeName}=${PropertyHelpers.getPropertyToken(nodeName)}`;
          (addedProperties as IDictionary<string>)[
            nodeName
          ] = `${PropertyHelpers.getPathToProperty(
            nodeName,
            false,
            DataFormat.XML,
            this.getDisplayParentNodePath(inputData)
          )}/text()`;
        }
      } else if (inputMode === DataFormat.HL7v2) {
        output = "headerName=--*headerName*--";
        (addedProperties as IDictionary<string>)["headerName"] =
          "$.Header.Name";
      } else {
        const propName = Object.keys(inputData || {})[0];
        output = `${propName}=${PropertyHelpers.getPropertyToken(propName)}`;
        (addedProperties as IDictionary<string>)[propName] =
          PropertyHelpers.getPathToProperty(propName, false, DataFormat.JSON);
      }
    } else if (
      [DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(outputMode)
    ) {
      this.loading = true;
      EndpointService.GetTemplate(
        this._appContext.managementHost,
        outputMode,
        this.tasks[task].output.fhir.resource
      ).then((result) => {
        this.tasks[task].setContent(Prettifier.prettify(result, outputMode));
        this.requestUpdatePreview().finally(() => {
          this.loading = false;
          this.tasks[task].output.loaded = true;
          this.tasks[task].output.modified = false;
        });
        this.updatePreview();
      });
    } else if (outputMode === DataFormat.HL7v2) {
      if (
        inputMode === DataFormat.XML &&
        (inputData as Document).documentElement
      ) {
        const nodeName = this.getDisplayNodeName(inputData);
        if (mapType === MapType.XSLT) {
          output = sampleXslt.replace(
            "{0}",
            `${nodeName}|<xsl:value-of select="${PropertyHelpers.getPathToProperty(
              nodeName,
              false,
              DataFormat.XML,
              this.getDisplayParentNodePath(inputData)
            )}"/>`
          );
        } else {
          output = `MSH|${PropertyHelpers.getPropertyToken(nodeName)}`;
          (addedProperties as IDictionary<string>)[
            nodeName
          ] = `${PropertyHelpers.getPathToProperty(
            nodeName,
            false,
            DataFormat.XML,
            this.getDisplayParentNodePath(inputData)
          )}/text()`;
        }
      } else if (inputMode === DataFormat.HL7v2) {
        output = "--*headerName*--";
        (addedProperties as IDictionary<string>)["headerName"] =
          "$.Header.Name";
      } else {
        if (
          typeof inputData === "object" &&
          (inputData as any).constructor !== Array
        ) {
          const propName = Object.keys(inputData)[0];
          output = `MSH|${PropertyHelpers.getPropertyToken(propName)}`;
          (addedProperties as IDictionary<string>)[propName] =
            PropertyHelpers.getPathToProperty(propName, false, DataFormat.JSON);
        } else {
          output = `MSH|${PropertyHelpers.getPropertyToken("[0]")}`;
          (addedProperties as IDictionary<string>)["[0]"] =
            PropertyHelpers.getPathToProperty("[0]", false, DataFormat.JSON);
        }
      }
    } else if (outputMode === DataFormat.HTML) {
      const html =
        "<html>\n\t<head>\n\t\t<title>AireGlu</title>\n\t</head>\n\t<body>\n\t\t<h1>Example Response</h1>\n\t</body>\n</html>";
      if (mapType === MapType.XSLT) {
        output = sampleXslt.replace("{0}", html);
      } else {
        output = html;
      }
    } else if (outputMode === DataFormat.Text) {
      if (
        inputMode === DataFormat.XML &&
        (inputData as Document).documentElement
      ) {
        const nodeName = this.getDisplayNodeName(inputData);
        if (mapType === MapType.XSLT) {
          output = sampleXslt.replace(
            "{0}",
            `${nodeName}: <xsl:value-of select="${PropertyHelpers.getPathToProperty(
              nodeName,
              false,
              DataFormat.XML,
              this.getDisplayParentNodePath(inputData)
            )}"/>`
          );
        } else {
          output = `${nodeName}: ${PropertyHelpers.getPropertyToken(nodeName)}`;
          (addedProperties as IDictionary<string>)[
            nodeName
          ] = `${PropertyHelpers.getPathToProperty(
            nodeName,
            false,
            DataFormat.XML,
            this.getDisplayParentNodePath(inputData)
          )}/text()`;
        }
      } else if (inputMode === DataFormat.HL7v2) {
        output = "headerName: --*headerName*--";
        (addedProperties as IDictionary<string>)["headerName"] =
          "$.Header.Name";
      } else {
        if (
          typeof inputData === "object" &&
          (inputData as any).constructor !== Array
        ) {
          const propName = Object.keys(inputData)[0];
          output = `${propName}: ${PropertyHelpers.getPropertyToken(propName)}`;
          (addedProperties as IDictionary<string>)[propName] =
            PropertyHelpers.getPathToProperty(propName, false, DataFormat.JSON);
        } else {
          output = `item: ${PropertyHelpers.getPropertyToken("[0]")}`;
          (addedProperties as IDictionary<string>)["[0]"] =
            PropertyHelpers.getPathToProperty("[0]", false, DataFormat.JSON);
        }
      }
    } else if (outputMode === DataFormat.Turtle) {
      if (
        inputMode === DataFormat.XML &&
        (inputData as Document).documentElement
      ) {
        const nodeName = this.getDisplayNodeName(inputData);
        if (mapType === MapType.XSLT) {
          output = sampleXslt.replace(
            "{0}",
            `${nodeName}|<xsl:value-of select="${PropertyHelpers.getPathToProperty(
              nodeName,
              false,
              DataFormat.XML,
              this.getDisplayParentNodePath(inputData)
            )}"/>`
          );
        } else {
          output =
            "@prefix : <http://example.org/stuff/1.0/> .\n" +
            "@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .\n" +
            `:a :b\n\t[ rdf:first "${PropertyHelpers.getPropertyToken(
              nodeName
            )}";\n\trdf:rest [ rdf:first "MyChild";\n\t\trdf:rest rdf:nil ]\n` +
            "] .";
          (addedProperties as IDictionary<string>)[
            nodeName
          ] = `${PropertyHelpers.getPathToProperty(
            nodeName,
            false,
            DataFormat.XML,
            this.getDisplayParentNodePath(inputData)
          )}/text()`;
        }
      } else if (inputMode === DataFormat.HL7v2) {
        output =
          "@prefix : <http://example.org/stuff/1.0/> .\n" +
          "@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .\n" +
          ':a :b\n\t[ rdf:first "--*headerName*--";\n\trdf:rest [ rdf:first "MyChild";\n\t\trdf:rest rdf:nil ]\n' +
          "] .";
        (addedProperties as IDictionary<string>)["headerName"] =
          "$.Header.Name";
      } else {
        if (
          typeof inputData === "object" &&
          (inputData as any).constructor !== Array
        ) {
          const propName = Object.keys(inputData)[0];
          output =
            "@prefix : <http://example.org/stuff/1.0/> .\n" +
            "@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .\n" +
            `:a :b\n\t[ rdf:first "${PropertyHelpers.getPropertyToken(
              propName
            )}";\n\trdf:rest [ rdf:first "MyChild";\n\t\trdf:rest rdf:nil ]\n` +
            "] .";
          (addedProperties as IDictionary<string>)[propName] =
            PropertyHelpers.getPathToProperty(propName, false, DataFormat.JSON);
        } else {
          output =
            "@prefix : <http://example.org/stuff/1.0/> .\n" +
            "@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .\n" +
            ':a :b\n\t[ rdf:first "' +
            PropertyHelpers.getPropertyToken("[0]") +
            '";\n\trdf:rest [ rdf:first "MyChild";\n\t\trdf:rest rdf:nil ]\n' +
            "] .";
          (addedProperties as IDictionary<string>)["[0]"] =
            PropertyHelpers.getPathToProperty("[0]", false, DataFormat.JSON);
        }
      }
    }
    return {
      code: output,
      addedProperties,
    };
  }

  public switchDataSource = async (dataSource: DataSource, task?: number) => {
    if (task !== undefined) {
      this.tasks[task].output.dataSource = dataSource;
    }
  };

  public HandleOutputMode = async (
    eventVal: any,
    inputMode: DataFormat,
    outputMode: DataFormat,
    resetContent: boolean = true,
    task?: number
  ) => {
    if (eventVal === "GET") {
      this.switchOutputMode(inputMode, outputMode, resetContent, task);
      this.switchDataSource(DataSource.QueryString, task);
    }
  };

  public switchOutputMode = async (
    inputMode: DataFormat,
    outputMode: DataFormat,
    resetContent: boolean = true,
    task?: number
  ) => {
    if (task !== undefined) {
      if (resetContent) {
        if (
          [Behaviour.Request, Behaviour.Response, Behaviour.Mapping].includes(
            this.tasks[task].behaviour
          )
        ) {
          this.tasks[task].setContent(
            this.getRequestExampleOutput(
              inputMode,
              outputMode,
              this.selectedDataOrigin.data,
              this.tasks[task].output.mapType,
              task
            ).code
          );
          this.tasks[task].output.mode = outputMode;
        } else if (this.tasks[task].behaviour === Behaviour.ResponseHandler) {
          this.exampleHelpers
            .GetResponseHandlerExampleOutput(
              outputMode,
              this.tasks[task].responseHandlerTypeField.value
            )
            .then((output) => {
              this.tasks[task].setContent(output.code);
              this.tasks[task].output.mode = outputMode;
              if (outputMode === DataFormat.Stream) {
                this.tasks[task].setContent(`ResponseStream[${task}]`);
                this.tasks[task].output.data = {
                  responseStream: `ResponseStream[${task}]`,
                };
              }
              this.updatePreview();
            });
        }
      } else {
        this.tasks[task].output.mode = outputMode;
      }
      if (outputMode === (DataFormat.HL7FHIRJSON || DataFormat.HL7FHIRXML)) {
        this.tasks[task].output.mapType = MapType.Path;
      }
    }
    if (outputMode === DataFormat.JSON || outputMode === DataFormat.XML) {
      this.tasks[task as number].output.dataSource = DataSource.Body;
    }
  };

  public switchInputMode = async (
    mode: DataFormat,
    resetContent: boolean = true
  ) => {
    this.input.mode = mode;
    if (resetContent) {
      await this.initializeInputData(mode, false);
    }
    if (mode === DataFormat.JSON || mode === DataFormat.XML) {
      this.input.dataSource = DataSource.Body;
    }
    this.generatable = DataFactory.IsGeneratable(mode);
    this.saveInput = mode === DataFormat.Callback || !this.generatable;
  };

  public switchMapType = async (
    mapType: MapType,
    inputMode: DataFormat,
    outputMode: DataFormat,
    resetContent: boolean = true,
    task: number
  ) => {
    // Switching maptype is only supported on the output step
    let output: IExampleOutput = { code: "", addedProperties: "" };
    if (resetContent) {
      if (
        [Behaviour.Request, Behaviour.Response, Behaviour.Mapping].includes(
          this.tasks[task].behaviour
        )
      ) {
        output = this.getRequestExampleOutput(
          inputMode,
          outputMode,
          this.selectedDataOrigin.data,
          mapType,
          task
        );
      } else if (this.tasks[task].behaviour === Behaviour.ResponseHandler) {
        output = await this.exampleHelpers.GetResponseHandlerExampleOutput(
          outputMode,
          this.tasks[task].responseHandlerTypeField.value
        );
      }
      this.tasks[task].setContent(output.code);
      this.tasks[task].addedProperties = output.addedProperties;
    }
    if (mapType === MapType.XSLT) {
      this.tasks[task].addedProperties = {};
    }
    this.tasks[task].output.mapType = mapType;
  };

  private getDisplayNodeName = (inputData: Document | {} | []) => {
    let nodeName = (inputData as Document).documentElement.nodeName;
    if ((inputData as Document).documentElement.children.length > 0) {
      nodeName = (inputData as Document).documentElement.children.item(
        0
      )!.nodeName;
    }
    return nodeName;
  };

  private getDisplayParentNodePath = (inputData: Document | {} | []) => {
    let path = `/`;
    if ((inputData as Document).documentElement.children.length > 0) {
      path = `${path}${(inputData as Document).documentElement.nodeName}`;
    }
    return path;
  };

  public async initializeInputData(mode: DataFormat, editMode: boolean) {
    await this._endpointHelpers
      .GetInputData(mode, this.input.fhir.resource)
      .then(async (result) => {
        this.inputContent = result.text;
        this.input.data = result.data;
        if (result.schema !== undefined) {
          this.inputSchema = result.schema;
        }
        if (!editMode && result.schema === undefined) {
          await this.updateInputSchema();
        }
      });
  }

  public updateInputData = autorun(
    () => {
      this.setInputData();
    },
    { delay: 500 }
  );

  public setInputData = async () => {
    this.input.modified = true;
    if (
      [DataFormat.JSON, DataFormat.HL7FHIRJSON, DataFormat.Callback].includes(
        this.input.mode
      )
    ) {
      this.input.data = Serializers.parseJsonIfValid(this.inputContent) as
        | {}
        | [];
    } else if (
      [DataFormat.XML, DataFormat.HL7FHIRXML].includes(this.input.mode)
    ) {
      this.input.data = Serializers.parseXmlIfValid(
        this.inputContent
      ) as Document;
    } else if (this.input.mode === DataFormat.QueryString) {
      this.input.data = Serializers.parseUrlIfValid(this.inputContent) as {};
    } else if (this.input.mode === DataFormat.HL7v2) {
      this.loading = true;
      EndpointService.GetInputData(this._appContext.managementHost, {
        input: this.inputContent,
        format: DataFormat.HL7v2,
      })
        .then((result) => {
          this.input.data = result;
          if (!result.success) this.input.error = result.error;
          else this.input.error = null;
        })
        .finally(() => (this.loading = false));
    } else if (this.input.mode === DataFormat.Stream) {
      this.input.data = { stream: "Stream" };
    }
    // Validate HL7 compliance
    if (
      [DataFormat.HL7FHIRJSON, DataFormat.HL7FHIRXML].includes(this.input.mode)
    ) {
      FHIRHelper.Valid(this.input.mode, this.inputContent).then((result) => {
        this.input.fhir.validationResult = result;
      });
    } else {
      this.input.fhir.validationResult = undefined;
    }
  };

  public updateInputContent = () => {
    if (
      [DataFormat.JSON, DataFormat.HL7FHIRJSON, DataFormat.HL7v2].includes(
        this.input.mode
      )
    ) {
      this.inputContent = Serializers.serializeJsonIfValid(
        this.input.data
      ) as string;
    } else if (
      [DataFormat.XML, DataFormat.HL7FHIRXML].includes(this.input.mode)
    ) {
      this.inputContent = Serializers.serializeXmlIfValid(
        this.input.data as Document
      ) as string;
    } else if (this.input.mode === DataFormat.QueryString) {
      this.inputContent = Serializers.serializeUrlIfValid(
        this.input.data
      ) as string;
    }
  };

  public switchInputFHIRResource = (
    resource: FHIRR4Resource,
    mode: DataFormat
  ) => {
    this.input.fhir.resource = resource;
    this.loading = true;
    EndpointService.GetTemplate(
      this._appContext.managementHost,
      mode,
      this.input.fhir.resource
    )
      .then((result) => {
        this.inputContent = Prettifier.prettify(result, mode);
      })
      .finally(() => (this.loading = false));
  };
}
